import axios from 'axios'
import React from 'react'
import { Subject } from 'rxjs'

export const JoleServiceContext = React.createContext()

//const activityLogUpdateSubject = new Subject();

class JoleService {

    constructor() { }

    // Odata Controller
    getUsers(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/User?$select=id,firstName,lastName,isActive&$count=true&$expand=studentInfo($expand=proofOfIncome),volunteerInfo,userRoles,contact&$top=' + top + '&$skip=' + skip + '&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getUsers4Export(orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/User?$select=id,firstName,lastName,gender,birthDate&$count=true&$expand=studentInfo,volunteerInfo,contact&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getNotInUnitUsers(query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/User?$select=id,firstName,lastName,studentInfoId,volunteerInfoId,employeeInfoId&$expand=Contact($select=email)' + (query ? '&$filter=' + query : ""))
    }
    getWorkingHours(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/WorkingHour?$expand=user($select=isActive,firstName,LastName;$expand=contact($select=email))&$select=id,checkedIn,checkedOut,note&$count=true&$top=' + top + '&$skip=' + skip + '&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getMyWorkingHours(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/WorkingHour?$expand=user($select=isActive,firstName,LastName;$expand=contact($select=email))&$select=id,checkedIn,checkedOut,note&$count=true&$top=' + top + '&$skip=' + skip + '&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getWorkingHours4Export(orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/WorkingHour?$expand=user($select=isActive,firstName,LastName;$expand=contact($select=email))&$select=id,checkedIn,checkedOut,note&$count=true&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getMyWorkingHours4Export(orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/WorkingHour?$expand=user($select=isActive,firstName,LastName;$expand=contact($select=email))&$select=id,checkedIn,checkedOut,note&$count=true&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getSessions(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/Session?$expand=Moderator,Unit($expand=School),UserSessions($expand=User,Attendance)&$select=id,title,description,startOn,endOn,startedOn,finishedOn,type,status&$count=true&$top=' + top + '&$skip=' + skip + '&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getSessionsForHour(query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/Session?$select=type,status,startOn,endOn,startedOn,finishedOn' + (query ? '&$filter=' + query : ""));
    }
    getSessions4Export(orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/Session?$select=Title,Description,Status,StartOn,EndOn,StartedOn,FinishedOn,OnlineMeetingRoomName,type&$expand=Moderator($select=FirstName,LastName),UserSessions($select=UserRoleType,User;$expand=User($select=FirstName,LastName,StudentInfo,VolunteerInfo;$expand=StudentInfo($select=ZoomEmail),VolunteerInfo($select=ZoomEmail)))&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getSessions4Import(query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/Session?$select=Title,StartOn,EndOn,Type,UserSessions&$expand=UserSessions($expand=User)&$orderby=startOn desc' + (query ? '&$filter=' + query : ""));
    }
    getStudentSessionReports(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/studentsessionreport?$expand=Usersession($select=user;$expand=user($select=firstName,lastName),session($select=title,starton,endon;$expand=Moderator($select=firstname,lastname))),volunteer($select=firstname,lastname)&$select=id,usersessionid,status&$count=true&$top=' + top + '&$skip=' + skip + '&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getStudentSessionReports4Export(orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/studentsessionreport?$expand=Usersession($select=user;$expand=user($select=firstName,lastName),session($select=title,starton,endon;$expand=Moderator($select=firstname,lastname))),volunteer($select=firstname,lastname)&$select=id,usersessionid,report&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getSchools(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/School?$select=id,name,division,isVolunteerApplicationAllowed,isStudentApplicationAllowed,sortOrder&$expand=units($select=id,isDeleted;$expand=userUnits($select=userId))&$count=true&$top=' + top + '&$skip=' + skip + '&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getSchoolsWithUnits() {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/School?$select=id,division,name,units,isVolunteerApplicationAllowed,isStudentApplicationAllowed,isMonday,isTuesday,isWednesday,isThursday,isFriday&$expand=Units($select=id,name;$filter=isDeleted eq false)');
    }
    getUnits(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/Unit?$expand=UserUnits,School&$select=id,Name,isActive&$count=true&$top=' + top + '&$skip=' + skip + '&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getBoards(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/Board?$expand=School,Unit&$select=id,name,description&$count=true&$top=' + top + '&$skip=' + skip + '&$orderby=school/id asc,' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getArticles(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/Article?$expand=Author,Replies($count=true;$top=0;$filter=isDeleted eq false)&$select=id,title,viewCount,createdOn,lastUpdatedOn&$count=true&$top=' + top + '&$skip=' + skip + '&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getMyBoards() {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/board?$select=id,school,unit,name&$expand=school($select=division,id,name),Unit($select=id,name)&$orderBy=school/division asc,school/id asc,unit/id asc,name asc');
    }
    getVolunteerHourLetters(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/VolunteerHourLetter?$expand=Volunteer,File&$select=id,periodStartDate,periodEndDate,hours,requestedOn,status,type,volunteerId&$count=true&$top=' + top + '&$skip=' + skip + '&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getAttendances(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/Attendance?$expand=UserSession($expand=user($select=firstname,lastname),session($select=title,starton,endon,status;$expand=moderator($select=firstname,lastname)))&$select=id,comment,type&$count=true&$top=' + top + '&$skip=' + skip + '&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getAttendancesForExport(orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/Attendance?$expand=UserSession($expand=user($select=firstname,lastname),session($select=title,starton,endon,status;$expand=moderator($select=firstname,lastname)))&$select=id,comment,type&$count=true&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getNeedApprovalApplicationCountForSchool(schoolId) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + "/User?$select=id&$expand=userUnits&$count=true&$top=1&$filter=(studentInfo/status eq 'SUBMITTED' and studentInfo/applicationSchoolId eq " + schoolId + " or volunteerInfo/status eq 'SUBMITTED' and volunteerInfo/volunteerAvailableDays/any(va: va/schoolId eq " + schoolId + "))");
    }
    getMeals(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/Meal?$select=id,description,providedOn,providedBy,servingCount&$expand=MealMenus&$count=true&$top=' + top + '&$skip=' + skip + '&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ''));
    }
    getLeadTeacherReports(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + '/leadTeacherReport?$expand=reportedBy($select=firstName,lastName)&$select=id,firstDate,status,report,reportedOn&$count=true&$top=' + top + '&$skip=' + skip + '&$orderby=' + orderBy + " " + order + (query ? '&$filter=' + query : ""));
    }
    getAvailableVolunteers(schoolId) {
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + "/user?$filter=volunteerInfo/volunteerAvailableDays/any(va: va/schoolId eq " + schoolId + ") and isActive eq true and volunteerInfo/status eq 'APPROVED' and userUnits/any(uu: uu/unit/schoolId eq " + schoolId + ")&$select=firstName,LastName&$expand=volunteerInfo($select=id,grade,volunteerAvailableDays;$expand=volunteerAvailableDays($filter=schoolId eq " + schoolId + "))")
    }

    // API Controller
    // Users
    getUsersApi(query) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Users?$select=id,firstName,lastName&$expand=Contact($select=email)')// + (query ? '&$filter=' + query : ""))
    }
    getCurrentUser() {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Users/me');
    }
    getUserData() {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Users/me/data');
    }
    getUser(id) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Users/' + id);
    }
    putUser(id, user) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/Users/' + id, user)
    }
    getModerators(schoolId = 0) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Users/GetModerators/' + (schoolId === 0 ? '' : schoolId));
    }
    getVolunteersByUnit(unitId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Users/getVolunteers/' + unitId);
    }
    //GetMyApplicationStatus(){
    //    return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Users/GetMyApplicationStatus');
    //}
    postAddNewEmployee(newEmployee) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Users/CreateEmployee', newEmployee)
    }
    postSendInvitation(employee) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Users/SendInvitation', employee)
    }
    removeRole(id,role) {
        return axios.delete(process.env.REACT_APP_JOLE_API_URL + '/Users/RemoveRole/'+ id + "?role=" +role)
    }
    // getVolunteers(unitId) {
    //     return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Users/getVolunteers/' + (unitId === null ? '' : unitId));
    // }
    getVolunteers() {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Users/getVolunteers');
    }
    getStudentsByUnit(unitId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Users/getStudents/' + unitId);
    }
    // getStudents(unitId) {
    //     return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Users/getStudents/' + (unitId === null ? '' : unitId));
    // }
    getStudents() {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Users/getStudents');
    }
    getLeadTeachers() {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Users/getLeadTeachers');
    }
    // application
    postStudent(userInfo) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Users/PostStudent', userInfo);
    }
    postVolunteer(userInfo) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Users/PostVolunteer', userInfo);
    }
    postLeadTeacher(userInfo) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Users/PostLeadTeacher', userInfo);
    }
    postAdministrator(userInfo) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Users/PostAdministrator', userInfo);
    }
    // GuardianContact
    getGuardianContact(id) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/GuardianContacts/' + id);
    }
    postGuardianContact(guardian) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Guardians', guardian)
    }
    putGuardianContact(id, guardian) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/GuardianContacts/' + id, guardian)
    }
    //Student
    getStudent(id) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/students/' + id)
    }
    //ProofOfIncome
    getProofOfIncomeFilesByProofOfIncome(proofOfIncomeId){
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/ProofOfIncomeFiles/GetProofOfIncomeFilesByProofOfIncome/' + proofOfIncomeId)
    }
    postProofOfIncomeFile(proofOfIncomeFile){
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/ProofOfIncomeFiles', proofOfIncomeFile)
    }
    deleteProofOfIncomeFile(proofOfIncomeFileId){
        return axios.delete(process.env.REACT_APP_JOLE_API_URL + '/ProofOfIncomeFiles/' + proofOfIncomeFileId)
    }

    //WorkingHour
    getCheckInStatus() {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/WorkingHours/CheckInStatus')
    }
    postCheckIn() {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/WorkingHours/CheckIn')
    }
    postCheckOut(id) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/WorkingHours/CheckOut/' + id)
    }
    getWorkingHour(id) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/WorkingHours/' + id)
    }

    putWorkingHour(id, workingHour) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/WorkingHours/' + id, workingHour)
    }
    //Unit
    getUnit(id) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Units/' + id)
    }
    putUnit(id, unit) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/Units/' + id, unit)
    }
    deleteUnit(unitId) {
        return axios.delete(process.env.REACT_APP_JOLE_API_URL + '/Units/' + unitId)
    }
    postUnit(unit) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Units', unit)
    }
    
    getUnitsBySchool(schoolId, onlyActive = false) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Units/UnitsBySchool/' + schoolId + (onlyActive ? "?isActive=true" : ""));
    }

    //School
    getAllSchools() {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Schools')
    }
    getSchool(schoolId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Schools/' + schoolId)
    }
    postSchool(school) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Schools', school)
    }
    putSchool(schoolId, school) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/Schools/' + schoolId, school)
    }
    deleteSchool(schoolId) {
        return axios.delete(process.env.REACT_APP_JOLE_API_URL + '/Schools/' + schoolId)
    }
    reorderSchool(schoolIds) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Schools/ReorderSchool', schoolIds)
    }
    //Session
    getSession(id) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Sessions/' + id)
    }
    postSession(session) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Sessions', session)
    }
    putSession(id, session) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/Sessions/' + id, session)
    }
    postStartSession(id) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Sessions/StartSession/' + id)
    }
    postEndSession(id) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Sessions/EndSession/' + id)
    }
    postBulkSession(sessions) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Sessions/PostBulkSession', sessions)
    }
    deleteSession(id) {
        return axios.delete(process.env.REACT_APP_JOLE_API_URL + '/Sessions/' + id)
    }
    // Meal
    getMeal(id) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Meals/' + id)
    }
    postMeal(meal) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Meals', meal)
    }
    putMeal(id, meal) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/Meals/' + id, meal)
    }
    deleteMeal(id) {
        return axios.delete(process.env.REACT_APP_JOLE_API_URL + '/Meals/' + id)
    }
    postMealAttachment(mealAttachment) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/MealAttachments', mealAttachment)
    }
    // hour letter
    getVolunteerHourLetter(id) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/VolunteerHourLetters/' + id)
    }
    postVolunteerHourLetter(hourLetter) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/VolunteerHourLetters/', hourLetter)
    }
    postGenerateVolunteerHourLetter(hourLetterId) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/VolunteerHourLetters/GenerateVolunteerHourLetter/' + hourLetterId)
    }
    putVolunteerHourLetter(id, hourLetter) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/VolunteerHourLetters/' + id, hourLetter)
    }
    GetVolunteerHourSessions(periodStartDate, periodEndDate, volunteerId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/VolunteerHourLetters/GetVolunteerHourSessions?periodStartDate=' + periodStartDate + '&periodEndDate=' + periodEndDate + '&volunteerId=' + volunteerId)
    }
    GetMyVolunteerHourSessions(periodStartDate, periodEndDate) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/VolunteerHourLetters/GetMyVolunteerHourSessions?periodStartDate=' + periodStartDate + '&periodEndDate=' + periodEndDate)
    }
    // board
    getAllBoards() {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Boards')
    }
    getBoardsBySchool(schoolId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Boards/BoardsBySchool/' + schoolId)
    }
    getBoardsByUnit(unitId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Boards/BoardsByUnit/' + unitId)
    }
    getBoard(boardId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Boards/' + boardId)
    }
    putBoard(boardId, board) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/Boards/' + boardId, board)
    }
    postBoard(board) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Boards/', board)
    }
    getArticle(articleId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Articles/' + articleId)
    }
    postArticle(article) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Articles/', article)
    }
    putArticle(articleId, article) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/Articles/' + articleId, article)
    }
    getReply(replyId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Replies/' + replyId)
    }
    postReply(reply) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Replies/', reply)
    }
    putReply(replyId, reply) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/Replies/' + replyId, reply)
    }
    getArticleAttachmentsByArticle(articleId){
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/ArticleAttachments/GetArticleAttachmentsByArticle/' + articleId)
    }
    postArticleAttachment(articleAttachment) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/ArticleAttachments', articleAttachment)
    }
    deleteAttachment(attachmentId) {
        return axios.delete(process.env.REACT_APP_JOLE_API_URL + '/ArticleAttachments/' + attachmentId)
    }
    // file
    postFile(file) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Files', file)
    }
    postFileWithThumbnail(file) {
        return axios.post(process.env.REACT_APP_JOLE_API_URL + '/Files/PostFileWithThumbnail', file)
    }
    getDownloadLink(fileId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Files/DownloadLink/' + fileId)
    }
    deleteFile(fileId) {
        return axios.delete(process.env.REACT_APP_JOLE_API_URL + '/Files/' + fileId)
    }
    // StudentSessionReport
    getStudentSessionReport(studentSessionReportId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/StudentSessionReports/' + studentSessionReportId)
    }
    putStudentSessionReport(studentSessionReportId, studentSessionReport) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/StudentSessionReports/' + studentSessionReportId, studentSessionReport)
    }
    getMyRemainedStudentProgressReports() {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + "/StudentSessionReports/MyRemainedStudentProgressReports/");
    }
    // Attendance
    getAttendance(attendanceId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Attendances/' + attendanceId)
    }
    getAttendancesApi(query) {
       // return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Attendances?$expand=UserSession($expand=user,session($expand=moderator))' + (query ? '&$filter=' + query : ""))
       return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Attendances?$select=id,type,comment&$expand=userSession($expand=user($select=firstName,lastName),session($select=id,status;$expand=moderator($select=firstname,lastname),unit($select=name)))' + (query ? '&$filter=' + query : ""))
       //return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Attendances?$expand=UserSession($expand=user($select=firstname,lastname))')
       //,session($select=title,starton,endon,status;$expand=moderator($select=firstname,lastname)))&$select=id,comment,type')
    }
    putAttendance(attendanceId, attendance) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/Attendances/' + attendanceId, attendance)
        
    }
    putDailyAttendance(attendances) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/Attendances/PutDailyAttendance',attendances)
    }
    // Calendar
    getCalendarSession(query){
        return axios.get(process.env.REACT_APP_JOLE_ODATA_URL + "/session?$select=id,title,starton,endon,status,type&$expand=userSessions($filter=user/volunteerInfoId ne null;$expand=user($select=volunteerInfoId))" + (query ? '&$filter=' + query : ""))
    }
    // LeadTeacherReport
    getLeadTeacherReport(reportId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/LeadTeacherReports/' + reportId)
    }
    putLeadTeacherReport(reportId, report) {
        return axios.put(process.env.REACT_APP_JOLE_API_URL + '/LeadTeacherReports/' + reportId, report)
    }
    getLeadTeacherReportAttendanceInfo(reportId) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/LeadTeacherReports/AttendanceInfo/' + reportId)
    }

    // Dashboard
    getDashboardTotal() {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Dashboard/DashboardTotal')
    }
    getDashboardApplications(dashboardPeriodType, dashboardRoleType, dashboardSchoolChoiceType) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Dashboard/Applications?periodType=' + dashboardPeriodType + '&roleType=' + dashboardRoleType + '&schooChoiceType=' + dashboardSchoolChoiceType)
    }
    getDashboardSessions(dashboardPeriodType, dashboardSchoolChoiceType) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Dashboard/Sessions?periodType=' + dashboardPeriodType + '&schooChoiceType=' + dashboardSchoolChoiceType)
    }
    getDashboardAttendances(dashboardPeriodType, dashboardRoleType, dashboardSchoolChoiceType) {
        return axios.get(process.env.REACT_APP_JOLE_API_URL + '/Dashboard/Attendances?periodType=' + dashboardPeriodType + '&roleType=' + dashboardRoleType + '&schooChoiceType=' + dashboardSchoolChoiceType)
    }

    makeFilterString(filters) {
        // {field:"",operator:"",value:""}
        var returnString = "";
        filters.forEach(entry => {
            if ((entry.value || entry.value === false) && entry.field) {
                returnString = returnString.concat((returnString === "" ? "" : " and ") + (entry.field + " " + entry.operator + " " +
                    ((typeof entry.value === 'string' || entry.value instanceof String) ? ("'" + entry.value + "'") :
                        ((typeof entry.value.getMonth === 'function' || entry.value instanceof Date) ? ("cast(" + entry.value.toISOString() + ",Edm.DateTimeOffset)") : entry.value))));
            } else if (entry.raw) {
                returnString = returnString.concat((returnString === "" ? "" : " and ") + entry.raw);
            }
        });
        return returnString;
    }
    insertUrlParam(kvObj) {
        if (kvObj && Object.keys(kvObj).length > 0) {
            let searchParams = new URLSearchParams(window.location.search);
            Object.keys(kvObj).forEach(key => {
                if (kvObj[key] !== '') {
                    searchParams.set(key, kvObj[key])
                }
                else {
                    searchParams.delete(key);
                }
            });
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.replaceState({ path: newurl }, '', newurl);
        } else if (Object.keys(kvObj).length === 0) {
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
            window.history.replaceState({ path: newurl }, '', newurl);
        }
    }
}

export default JoleService