// ** Initial State
const initialState = {
    submittedApplicationCount : 0,
    currentSchool: JSON.parse(localStorage.getItem('currentSchool')) ? JSON.parse(localStorage.getItem('currentSchool')) : {id:0,name:""},
    remainedReports : []
  }
  
  const appDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_APPLICATION_COUNT':
        return { ...state, submittedApplicationCount: action.val }
      case 'UPDATE_CURRENT_SCHOOL':
        localStorage.setItem('currentSchool',JSON.stringify(action.data));
        return { ...state, currentSchool: action.data}
      case 'UPDATE_REMAINED_REPORTS':
        return { ...state, remainedReports: action.val}
      default:
        var currentSchool = JSON.parse(localStorage.getItem('currentSchool')) ? JSON.parse(localStorage.getItem('currentSchool')) : {id:0,name:""}
        return { ...state, currentSchool:currentSchool }
    }
  }
  
  export default appDataReducer
  